import React, { Fragment, useEffect, useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { listProductQuery, listProducts } from "../../actions/productActions";
import Message from "../../components/Message";
import ProductsLoader from "../../components/ProductLoader/ProductsLoader";
import styles from "./DynamicProducts.module.css";
import "./DynamicProducts.css";
import { useNavigate, useParams } from "react-router-dom";
import IdvProduct from "../IdvProduct_/IdvProduct";
import Loader from "../Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const DynamicProducts = ({ Ref, gen, cate, bran }) => {
  const [query, setQuery] = useState(undefined);
  const [gender, setGender] = useState(gen ? gen : undefined);
  const [category, setCategory] = useState(cate ? cate.replace(/-/g, ' ') : " ");
  const [brand, setBrand] = useState(bran ? bran : "none");
  const history = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const productQuery = useSelector((state) => state.productQuery);
  const { loading, error, products } = productQuery;

  const productList = useSelector((state) => state.productList);
  const { loading: Loading, products: Products } = productList;

  const keyword = params.keyword;

  useEffect(() => {
    setCategory(cate.replace(/-/g, ' '));
  }, [cate]);

  useEffect(() => {
    dispatch(listProducts());
  }, [gen, gender, bran, cate, brand, dispatch]);

  useEffect(() => {
    if (keyword || category || gender || brand) {
      setQuery(keyword ? keyword : undefined);
      dispatch(
        listProductQuery(
          keyword ? keyword : " ",
          gender ? gender : " ",
          category ? category : " ",
          brand ? brand : "none"
        )
      );
    } else {
      dispatch(listProductQuery());
    }
  }, [dispatch, keyword, params, gender, category, gen, bran, cate, brand]);

  const querySubmitHandler = (e) => {
    e.preventDefault();
    if (brand !== "none" && query.trim()) {
      history(`/shop/${brand}/search/${query}`);
    } else {
      if (brand !== "none") {
        history(`/shop/${brand}`);
      } else {
        if (query.trim()) {
          history(`/shop/search/${query}`);
        } else {
          history("/shop");
        }
      }
    }
  };

  return (
    <Fragment>
      <div ref={Ref} className={`${styles.main} `}>
        <Container>
          <Row className={styles.filter}>
            <div className={styles.search}>
              <form
                onSubmit={querySubmitHandler}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <input
                  maxLength="24"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Search"
                />{" "}
                <span>
                  <img
                    alt=""
                    onClick={querySubmitHandler}
                    type="submit"
                    src="/images/search.svg"
                  />
                </span>
              </form>
            </div>
            <div className={styles.gender}>
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="">Shop Categories</option>
                <option value="12V Batteries">12V Batteries</option>
                <option value="24V Batteries">24V Batteries</option>
                <option value="36V Batteries">36V Batteries</option>
                <option value="48V Batteries">48V Batteries</option>
                <option value="Starting Batteries">Starting Batteries</option>
                <option value="Solar Batteries">Solar Batteries</option>
                <option value="Ecoflow Powerstations">
                  Ecoflow Powerstations
                </option>
                <option value="Ecoflow Portable Solar Panels">
                  Ecoflow Portable Solar Panels
                </option>
                <option value="Rigid Solar Panels">Rigid Solar Panels</option>
                <option value="Off Grid Solar Systems">
                  Off Grid Solar Systems
                </option>
                <option value="Residential Solar Systems">
                  Residential Solar Systems
                </option>
                <option value="Portable Solar Generators">
                  Portable Solar Generators
                </option>
                <option value="Chargers And Accessories">
                  Chargers And Accessories
                </option>
              </select>
            </div>
          </Row>

          <Row className="gy-5 pt-5">
            <Col lg={4}>
              <div className={styles.categories}>
                <h5 className="h5_main">Category</h5>

                <div className={styles.mobList}>
                  <Swiper
                    pagination={{
                      dynamicBullets: true,
                      clickable: true,
                    }}
                    slidesPerView={1}
                    modules={[Pagination]}
                    className={styles.swiper}
                  >
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === ""
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("")}
                      >
                        <h6>Shop Categories</h6>
                        {Loading ? (
                          <span>
                            {" "}
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {gender && brand === "none"
                              ? Products.filter(
                                  (item) => item.gender === gender
                                ).length
                              : !gender && brand !== "none"
                              ? Products.filter((item) => item.brand === brand)
                                  .length
                              : gender && brand !== "none"
                              ? Products.filter(
                                  (item) =>
                                    item.gender === gender &&
                                    item.brand === brand
                                ).length
                              : Products.length}
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "12V Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("12V Batteries")}
                      >
                        <h6>12V Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "12V Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "12V Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "12V Batteries"
                                  : item.category === "12V Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "24V Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("24V Batteries")}
                      >
                        <h6>24V Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />{" "}
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "24V Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "24V Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "24V Batteries"
                                  : item.category === "24V Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "36V Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("36V Batteries")}
                      >
                        <h6>24V Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "36V Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "36V Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "36V Batteries"
                                  : item.category === "36V Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "48V Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("48V Batteries")}
                      >
                        <h6>48V Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "48V Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "48V Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "48V Batteries"
                                  : item.category === "48V Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Starting Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Starting Batteries")}
                      >
                        <h6>Starting Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Starting Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Starting Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Starting Batteries"
                                  : item.category === "Starting Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Solar Batteries"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Solar Batteries")}
                      >
                        <h6>Solar Batteries</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Solar Batteries"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Solar Batteries"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Solar Batteries"
                                  : item.category === "Solar Batteries"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Ecoflow Powerstations"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Ecoflow Powerstations")}
                      >
                        <h6>Ecoflow Powerstations</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Ecoflow Powerstations"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Ecoflow Powerstations"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Ecoflow Powerstations"
                                  : item.category === "Ecoflow Powerstations"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Portable Solar Generators"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Portable Solar Generators")}
                      >
                        <h6>Portable Solar Generators</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category ===
                                      "Portable Solar Generators"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category ===
                                      "Portable Solar Generators"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category ===
                                      "Portable Solar Generators"
                                  : item.category ===
                                    "Portable Solar Generators"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Ecoflow Portable Solar Panels"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() =>
                          setCategory("Ecoflow Portable Solar Panels")
                        }
                      >
                        <h6>Ecoflow Portable Solar Panels</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category ===
                                      "Ecoflow Portable Solar Panels"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category ===
                                      "Ecoflow Portable Solar Panels"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category ===
                                      "Ecoflow Portable Solar Panels"
                                  : item.category ===
                                    "Ecoflow Portable Solar Panels"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Rigid Solar Panels"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Rigid Solar Panels")}
                      >
                        <h6>Rigid Solar Panels</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Rigid Solar Panels"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Rigid Solar Panels"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Rigid Solar Panels"
                                  : item.category === "Rigid Solar Panels"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Off Grid Solar Systems"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Off Grid Solar Systems")}
                      >
                        <h6>Off Grid Solar Systems</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Off Grid Solar Systems"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Off Grid Solar Systems"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Off Grid Solar Systems"
                                  : item.category === "Off Grid Solar Systems"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Residential Solar Systems"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Residential Solar Systems")}
                      >
                        <h6>Residential Solar Systems</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category ===
                                      "Residential Solar Systems"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category ===
                                      "Residential Solar Systems"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category ===
                                      "Residential Solar Systems"
                                  : item.category ===
                                    "Residential Solar Systems"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>

                    <SwiperSlide className={styles.swiperSlide}>
                      <div
                        className={
                          category === "Chargers And Accessories"
                            ? `${styles.boxActive} ${styles.box}`
                            : styles.box
                        }
                        onClick={() => setCategory("Chargers And Accessories")}
                      >
                        <h6>Chargers And Accessories</h6>
                        {Loading ? (
                          <span>
                            <Loader width="15px" height="15px" />
                          </span>
                        ) : (
                          <span>
                            (0
                            {
                              Products.filter((item) =>
                                gender && brand === "none"
                                  ? item.gender === gender &&
                                    item.category === "Chargers And Accessories"
                                  : !gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.category === "Chargers And Accessories"
                                  : gender && brand !== "none"
                                  ? item.brand === brand &&
                                    item.gender === gender &&
                                    item.category === "Chargers And Accessories"
                                  : item.category === "Chargers And Accessories"
                              ).length
                            }
                            )
                          </span>
                        )}
                      </div>
                    </SwiperSlide>
                  </Swiper>
                </div>
                <div className={styles.list}>
                  <div
                    className={
                      category === ""
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("")}
                  >
                    <h6>Shop Categories</h6>
                    {Loading ? (
                      <span>
                        {" "}
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {gender && brand === "none"
                          ? Products.filter((item) => item.gender === gender)
                              .length
                          : !gender && brand !== "none"
                          ? Products.filter((item) => item.brand === brand)
                              .length
                          : gender && brand !== "none"
                          ? Products.filter(
                              (item) =>
                                item.gender === gender && item.brand === brand
                            ).length
                          : Products.length}
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "12V Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("12V Batteries")}
                  >
                    <h6>12V Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "12V Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "12V Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "12V Batteries"
                              : item.category === "12V Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>

                  <div
                    className={
                      category === "24V Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("24V Batteries")}
                  >
                    <h6>24V Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />{" "}
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "24V Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "24V Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "24V Batteries"
                              : item.category === "24V Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "36V Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("36V Batteries")}
                  >
                    <h6>36V Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "36V Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "36V Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "36V Batteries"
                              : item.category === "36V Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "48V Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("48V Batteries")}
                  >
                    <h6>48V Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "48V Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "48V Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "48V Batteries"
                              : item.category === "48V Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Starting Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Starting Batteries")}
                  >
                    <h6>Starting Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Starting Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Starting Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Starting Batteries"
                              : item.category === "Starting Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Solar Batteries"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Solar Batteries")}
                  >
                    <h6>Solar Batteries</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Solar Batteries"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Solar Batteries"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Solar Batteries"
                              : item.category === "Solar Batteries"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Ecoflow Powerstations"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Ecoflow Powerstations")}
                  >
                    <h6>Ecoflow Powerstations</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Ecoflow Powerstations"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Ecoflow Powerstations"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Ecoflow Powerstations"
                              : item.category === "Ecoflow Powerstations"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Portable Solar Generators"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Portable Solar Generators")}
                  >
                    <h6>Portable Solar Generators</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Portable Solar Generators"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Portable Solar Generators"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Portable Solar Generators"
                              : item.category === "Portable Solar Generators"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Ecoflow Portable Solar Panels"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Ecoflow Portable Solar Panels")}
                  >
                    <h6>Ecoflow Portable Solar Panels</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category ===
                                  "Ecoflow Portable Solar Panels"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category ===
                                  "Ecoflow Portable Solar Panels"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category ===
                                  "Ecoflow Portable Solar Panels"
                              : item.category ===
                                "Ecoflow Portable Solar Panels"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>

                  <div
                    className={
                      category === "Rigid Solar Panels"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Rigid Solar Panels")}
                  >
                    <h6>Rigid Solar Panels</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Rigid Solar Panels"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Rigid Solar Panels"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Rigid Solar Panels"
                              : item.category === "Rigid Solar Panels"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>

                  <div
                    className={
                      category === "Off Grid Solar Systems"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Off Grid Solar Systems")}
                  >
                    <h6>Off Grid Solar Systems</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Off Grid Solar Systems"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Off Grid Solar Systems"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Off Grid Solar Systems"
                              : item.category === "Off Grid Solar Systems"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                  <div
                    className={
                      category === "Residential Solar Systems"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Residential Solar Systems")}
                  >
                    <h6>Residential Solar Systems</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Residential Solar Systems"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Residential Solar Systems"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Residential Solar Systems"
                              : item.category === "Residential Solar Systems"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>

                  <div
                    className={
                      category === "Chargers And Accessories"
                        ? `${styles.boxActive} ${styles.box}`
                        : styles.box
                    }
                    onClick={() => setCategory("Chargers And Accessories")}
                  >
                    <h6>Chargers And Accessories</h6>
                    {Loading ? (
                      <span>
                        <Loader width="15px" height="15px" />
                      </span>
                    ) : (
                      <span>
                        (0
                        {
                          Products.filter((item) =>
                            gender && brand === "none"
                              ? item.gender === gender &&
                                item.category === "Chargers And Accessories"
                              : !gender && brand !== "none"
                              ? item.brand === brand &&
                                item.category === "Chargers And Accessories"
                              : gender && brand !== "none"
                              ? item.brand === brand &&
                                item.gender === gender &&
                                item.category === "Chargers And Accessories"
                              : item.category === "Chargers And Accessories"
                          ).length
                        }
                        )
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </Col>
            <Col md={12} lg={8}>
              {loading ? (
                <ProductsLoader length={6} />
              ) : error ? (
                <Message variant="danger">{error}</Message>
              ) : products.length === 0 ? (
                <h2
                  style={{
                    fontStyle: "normal",
                    textTransform: "none",
                    color: "red",
                  }}
                  className="h2_main pt-5 text-center"
                >
                  NO MATCHING ITEMS
                </h2>
              ) : (
                <Row className="gx-lg-5 gy-5">
                  {products.map((product) => (
                    <Col
                      style={{ marginBottom: "35px", height: "auto" }}
                      key={product._id}
                      sm={12}
                      lg={6}
                    >
                      <IdvProduct productPage={true} product={product} />
                    </Col>
                  ))}
                </Row>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Fragment>
  );
};

export default DynamicProducts;
