import React, { useState } from "react";
import styles from "./Header.module.css";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { LinkContainer } from "react-router-bootstrap";
import { logout } from "../../actions/userActions";
import { Col, Container, Nav, NavDropdown, Navbar, Row } from "react-bootstrap";

const Header = () => {
  const [query, setQuery] = useState(undefined);
  const [showBars, setShowBars] = useState(true);
  const [showCross, setShowCross] = useState(false);
  const [mobSearch, setMobSearch] = useState(false);

  const history = useNavigate();

  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const ttlQty = cartItems.length;

  const logoutHandler = () => {
    dispatch(logout());
    window.location.reload(false);
  };

  const signInHandler = () => {
    localStorage.removeItem("googleUserData");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  const toggleHandler = () => {
    if (showCross) {
      setShowCross(false);
      setShowBars(true);
    }
  };
  const barsClickHandler = () => {
    setShowCross(true);
    setShowBars(false);
  };
  const crossClickHandler = () => {
    setShowCross(false);
    setShowBars(true);
  };

  const querySubmitHandler = (e) => {
    e.preventDefault();
    setMobSearch(false);
    if (query.trim()) {
      history(`/shop/search/${query}`);
    } else {
      history("/");
    }
  };

  return (
    <>
      <header className={styles.headerDesktop}>
        <Container>
          <div className={styles.main}>
            <div className={styles.logo}>
              <Link to="/home">
                <img alt="Tru Off Grid" src="/images/logo.png" />
              </Link>
            </div>
            <div className={styles.infoCont}>
              <div className={styles.tabs}>
                <div className={styles.dropdown}>
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.tabLink}  ${styles.tabLinkActive} `
                        : styles.tabLink
                    }
                  >
                    About
                  </NavLink>
                  <div className={styles.dropdownContentCont}>
                    <div className={styles.dropdownContent}>
                      <NavLink
                        to="/partners"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main">Our Partners</h6>
                      </NavLink>
                    </div>
                  </div>
                </div>

                <div className={styles.dropdown}>
                  <NavLink
                    to="/shop"
                    className={({ isActive }) =>
                      isActive
                        ? `${styles.tabLink}  ${styles.tabLinkActive} `
                        : styles.tabLink
                    }
                  >
                    Shop
                  </NavLink>
                  <div className={styles.dropdownContentCont}>
                    <div className={styles.dropdownContent}>
                      <NavLink
                        to="/shop/12V-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">12V Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/24V-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">24V Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/36V-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">36V Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/48V-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">48V Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Starting-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">Starting Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Solar-Batteries"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">Solar Batteries</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Ecoflow-Powerstations"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">Ecoflow Powerstations</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Portable-Solar-Generators"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">
                          Portable Solar Generators
                        </h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Ecoflow-Portable-Solar-Panels"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">
                          Ecoflow Portable Solar Panels
                        </h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Rigid-Solar-Panels"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">Rigid Solar Panels</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Off-Grid-Solar-Systems"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">Off Grid Solar Systems</h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Residential-Solar-Systems"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main pb-3">
                          Residential Solar Systems
                        </h6>
                      </NavLink>
                      <NavLink
                        to="/shop/Chargers-And-Accessories"
                        className={({ isActive }) =>
                          isActive
                            ? `${styles.dropdownContentActiveLink} ${styles.dropdownContentLink}`
                            : styles.dropdownContentLink
                        }
                      >
                        <h6 className="h6_main">Chargers And Accessories</h6>
                      </NavLink>
                    </div>
                  </div>
                </div>
                {/* <a
                  target="no_blank"
                  href="https://urtsolar.ca/"
                  className={styles.tabLink}
                >
                  URT Solar Installation
                </a> */}
                <NavLink
                  to="/battery-technology"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.tabLink}  ${styles.tabLinkActive} `
                      : styles.tabLink
                  }
                >
                  Battery Technology
                </NavLink>
                <NavLink
                  to="/solar-installation"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.tabLink}  ${styles.tabLinkActive} `
                      : styles.tabLink
                  }
                >
                  Solar Installation
                </NavLink>
                <NavLink
                  to="/commercial"
                  className={({ isActive }) =>
                    isActive
                      ? `${styles.tabLink}  ${styles.tabLinkActive} `
                      : styles.tabLink
                  }
                >
                  Commercial
                </NavLink>
              </div>

              <a style={{ textDecoration: "none" }} href="tel: 905-299-7841">
                <button
                  style={{ padding: "8px 20px 8px 20px", boxShadow: "none" }}
                  className="button_main me-2"
                >
                  <img src="/images/icons/phone.svg" />
                  905-299-7841
                </button>
              </a>

              <div className={styles.search}>
                <form
                  onSubmit={querySubmitHandler}
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span>
                    <img src="/images/search.svg" />
                  </span>
                  <input
                    maxLength="22"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    type="text"
                    placeholder="Search"
                  />
                </form>
              </div>

              <NavLink to="/cart" className={styles.iconText}>
                <img src="/images/bag.svg" />
                <p style={{ color: "black" }} className="para_main">
                  ({`${ttlQty}`})
                </p>
              </NavLink>

              {userInfo ? (
                <NavDropdown
                  style={{ color: "black" }}
                  title={userInfo.firstName}
                  className={styles.username}
                >
                  {userInfo && userInfo.isAdmin && (
                    <>
                      <LinkContainer activeClassName="" to="/admin/userlist">
                        <NavDropdown.Item>Users</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer activeClassName="" to="/admin/productlist">
                        <NavDropdown.Item>Products</NavDropdown.Item>
                      </LinkContainer>

                      <LinkContainer activeClassName="" to="/admin/orderlist">
                        <NavDropdown.Item>Orders</NavDropdown.Item>
                      </LinkContainer>
                    </>
                  )}
                  <LinkContainer activeClassName="" to="/profile">
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer activeClassName="" to="/myorders">
                    <NavDropdown.Item>My Orders</NavDropdown.Item>
                  </LinkContainer>

                  <NavDropdown.Item onClick={logoutHandler}>
                    Log out
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <NavLink
                  className={styles.iconText}
                  to="/login"
                  onClick={signInHandler}
                >
                  <img src="/images/user.svg"></img>{" "}
                  <p className="h4_main">Sign In</p>
                </NavLink>
              )}
            </div>
          </div>
        </Container>
      </header>

      {mobSearch && (
        <header className={styles.mobSearch}>
          <div className={styles.searchCont}>
            <div style={{ marginRight: "12px" }} className={styles.search}>
              <form
                onSubmit={querySubmitHandler}
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <span>
                  <img src="/images/search.svg" />
                </span>
                <input
                  maxLength="22"
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  type="text"
                  placeholder="Search Products"
                />
              </form>
            </div>
            <img
              onClick={() => setMobSearch(false)}
              className={styles.close}
              src="/images/close.svg"
            />
          </div>
        </header>
      )}

      {!mobSearch && (
        <header className={styles.mobHeader}>
          <Navbar
            className={styles.mobNav}
            expand="xxl"
            collapseOnSelect
            onToggle={toggleHandler}
          >
            <Container>
              <div className={styles.logoCont}>
                <Link to="/home">
                  <img src="/images/logo.png" />{" "}
                </Link>
              </div>

              <Navbar className="ms-auto">
                {userInfo ? (
                  <NavDropdown
                    className={styles.username}
                    title={userInfo.firstName}
                  >
                    {userInfo && userInfo.isAdmin && (
                      <>
                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/userlist"
                        >
                          <NavDropdown.Item>Users</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/productlist"
                        >
                          <NavDropdown.Item>Products</NavDropdown.Item>
                        </LinkContainer>

                        <LinkContainer
                          activeClassName=""
                          style={{ paddingLeft: "12px", paddingTop: "6px" }}
                          to="/admin/orderlist"
                        >
                          <NavDropdown.Item>Orders</NavDropdown.Item>
                        </LinkContainer>
                      </>
                    )}
                    <LinkContainer
                      activeClassName=""
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      to="/profile"
                    >
                      <NavDropdown.Item>Profile</NavDropdown.Item>
                    </LinkContainer>
                    <LinkContainer
                      activeClassName=""
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      to="/myorders"
                    >
                      <NavDropdown.Item>My Orders</NavDropdown.Item>
                    </LinkContainer>
                    <NavDropdown.Item
                      style={{ paddingLeft: "12px", paddingTop: "6px" }}
                      onClick={logoutHandler}
                    >
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                ) : (
                  <NavLink
                    className={styles.mobIcon}
                    to="/login"
                    onClick={signInHandler}
                  >
                    <img
                      style={{ marginRight: "12px" }}
                      src="/images/user.svg"
                    ></img>
                  </NavLink>
                )}

                <span
                  style={{
                    marginRight: "12px",
                    width: "15px",
                    height: "15px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  onClick={() => setMobSearch(true)}
                >
                  <img
                    style={{ width: "100%", height: "100%" }}
                    src="/images/search.svg"
                  />
                </span>

                <NavLink to="/cart" className={styles.mobIcon}>
                  <img src="/images/bag.svg" />({`${ttlQty}`})
                </NavLink>
              </Navbar>

              {showBars && (
                <Navbar.Toggle
                  as="button"
                  className={styles.mobToggler}
                  onClick={barsClickHandler}
                  aria-controls="basic-navbar-nav"
                >
                  <i className="fa-solid fa-bars"></i>
                </Navbar.Toggle>
              )}
              {showCross && (
                <Navbar.Toggle
                  as="button"
                  className={styles.mobToggler}
                  onClick={crossClickHandler}
                  aria-controls="basic-navbar-nav"
                >
                  <i className="fa-solid fa-xmark"></i>
                </Navbar.Toggle>
              )}
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav
                  style={{ paddingTop: "20px", gap: "7px" }}
                  className={`${styles.mobNav} ms-auto`}
                >
                  <NavLink
                    to="/about"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    About
                  </NavLink>
                  <Row className="gy-2" style={{ paddingLeft: "15px" }}>
                    <NavLink
                      to="/partners"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Our Partners
                    </NavLink>
                  </Row>
                  <NavLink
                    to="/shop"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Shop
                  </NavLink>

                  <Row className="gy-2" style={{ paddingLeft: "15px" }}>
                    <NavLink
                      to="/shop/12V-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      12V Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/24V-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      24V Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/36V-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      36V Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/48V-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      48V Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/Starting-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Starting Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/Solar-Batteries"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Solar Batteries
                    </NavLink>
                    <NavLink
                      to="/shop/Ecoflow-Powerstations"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Ecoflow Powerstations
                    </NavLink>
                    <NavLink
                      to="/shop/Portable-Solar-Generators"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Portable Solar Generators
                    </NavLink>
                    <NavLink
                      to="/shop/Ecoflow-Portable-Solar-Panels"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Ecoflow Portable Solar Panels
                    </NavLink>
                    <NavLink
                      to="/shop/Rigid-Solar-Panels"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Rigid Solar Panels
                    </NavLink>
                    <NavLink
                      to="/shop/Off-Grid-Solar-Systems"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Off Grid Solar Systems
                    </NavLink>
                    <NavLink
                      to="/shop/Residential-Solar-Systems"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Residential Solar Systems
                    </NavLink>
                    <NavLink
                      to="/shop/Chargers-And-Accessories"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : styles.link
                      }
                    >
                      Chargers And Accessories
                    </NavLink>
                  </Row>

                  {/* <a
                    target="no_blank"
                    href="https://urtsolar.ca/"
                    className={styles.link}
                  >
                    URT Solar Installation
                  </a> */}
                  <NavLink
                    to="/battery-technology"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Battery Technology
                  </NavLink>
                  <NavLink
                    to="/solar-installation"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Solar Installation
                  </NavLink>
                  <NavLink
                    to="/commercial"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : styles.link
                    }
                  >
                    Commercial
                  </NavLink>
                  <a
                    style={{ textDecoration: "none" }}
                    href="tel: 905-299-7841"
                  >
                    <button
                      style={{
                        padding: "8px 20px 8px 20px",
                        boxShadow: "none",
                      }}
                      className="button_main me-2"
                    >
                      <img src="/images/icons/phone.svg" />
                      905-299-7841
                    </button>
                  </a>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      )}
    </>
  );
};

export default Header;
